import React from 'react';

import './footer.css';

import privacy from '../../assets/docs/privacy-policy-ru.pdf';
import reestr from '../../assets/docs/reestr.pdf';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="footer-registry">
            <span className="footer-registry__company">
                © Общество с ограниченной ответственностью "Автотех", {currentYear}
            </span>

            <div className="footer-registry__info">
                <div className="footer-registry__info-company">© Общество с ограниченной ответственностью "Автотех", {currentYear}</div>
                <div className="footer-registry__info-main">
                    ИНН 9725045830, ОГРН 1217700104160, КПП 772501001<br />
                    Адрес места нахождения: 115432, Россия, г. Москва, проспект Андропова, дом 10А
                </div>
                <div className="footer-registry__info-bottom">
                    <span className="footer-registry__info-bottom-item">Основной вид деятельности: ОКВЭД 62.01</span>
                    <a href={reestr} target="_blank" className="footer-registry__info-bottom-item">Аккредитация в качестве ИТ-компании</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
