import React from 'react';

import icon2 from '../../assets/icons/icon-2.svg';
import icon3 from '../../assets/icons/icon-3.svg';
import Screen from '../../components/screen/screen';
import type { IRegistryScreenData } from '../../types/ScreenData';

const data: IRegistryScreenData = {
    hero: {
        title: 'Connected <br/>Car',
        text : 'Мобильное приложение для&nbsp;удалённого управления и&nbsp;контроля автомобиля: запуск двигателя, заправка, оплата штрафов, отслеживание баланса транспондера и&nbsp;другой функционал.'
    },
    sections: [
        {
            title  : 'Возможности приложения',
            icon   : icon2,
            content: `
      <ul class="screen__list">
        <li><b>Управление </b><span>возможность удаленного запуска двигателя для&nbsp;прогрева или&nbsp;охлаждения автомобиля повышает уровень комфорта владения</span></li>
        <li><b>Контроль </b><span>местоположение, пробег, уровень топлива и&nbsp;другая важная информация всегда под&nbsp;рукой</span></li>
        <li><b>Сервисы </b><span>отслеживание и&nbsp;оплата штрафов, контроль баланса транспондера, запись на&nbsp;сервис</span></li>
        <li><b>Онлайн-заправка </b><span>комфортный и&nbsp;быстрый процесс заправки автомобиля</span></li>
        <li><b>Журнал </b><span>история поездок и&nbsp;другие важные события</span></li>
      </ul>
      `
        },
        {
            title  : 'Стоимость использования программы',
            icon   : icon2,
            content: 'рассчитывается индивидуально в&nbsp;зависимости от&nbsp;функционала, объёмов и&nbsp;других условий. Узнать подробности можно по&nbsp;адресу электронной почты <a href="mailto:partners@autotech.team">partners@autotech.team</a>'
        },
        {
            title  : 'Документация',
            icon   : icon3,
            content: `
      <ul>
        <li><a href="/registry/opisanie-po.pdf" target="_blank">Описание ПО</a></li>
        <li><a href="/registry/instrukciya-playauto.pdf" target="_blank">Руководство по установке и эксплуатации</a></li>
      </ul>
      `
        }
    ]
};

const ConnectedCarComponent = () => {
    return <Screen data={data} />;
};

export default ConnectedCarComponent;
