import React, { Fragment } from 'react';

import type { IRegistryScreenData, IRegistrySection } from '../../types/ScreenData';
import Divider from '../divider/divider';
import Footer from '../footer/footer';
import Heading from '../heading/heading';
import Hero from '../hero/hero';

import './screen.css';

interface IScreenProps {
    data: IRegistryScreenData
}

const Screen = ({ data: { hero, sections } }: IScreenProps) => {
    return (
        <div className="screen">
            <Hero title={hero.title} text={hero.text} />
            <div className="screen__container">
                <div className="screen__container-right">
                    {sections.map((section: IRegistrySection, idx: number) => {
                        const isLast = idx === sections.length - 1;
                        return (
                            <Fragment key={section.title}>
                                <section className="screen__section">
                                    <Heading icon={section.icon} text={section.title} />
                                    {section.content && (
                                        <div
                                            className="screen__text"
                                            dangerouslySetInnerHTML={{ __html: section.content }}
                                        />
                                    )}
                                    {section.tags && (
                                        <div className="screen__tags">
                                            {section.tags.map((tag: string) => (
                                                <div
                                                    key={tag} className="screen__tags-item"
                                                    dangerouslySetInnerHTML={{ __html: tag }}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </section>
                                {!isLast && <Divider />}
                            </Fragment>
                        );
                    })}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Screen;
