import React from 'react';

import './hero.css';

interface IHeroProps {
    title: string,
    text: string
}

const Hero = ({ title, text }: IHeroProps) => {
    return (
        <div className="hero">
            <div className="hero__inner">
                <h1 className="hero__inner-title" dangerouslySetInnerHTML={{ __html: title }} />
                <p className="hero__inner-text" dangerouslySetInnerHTML={{ __html: text }} />
            </div>
        </div>
    );
};

export default Hero;
