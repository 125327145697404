import React from 'react';

import ConnectedCarComponent from '@/components/registry/screens/ConnectedCar/ConnectedCar';

const ConnectedCar = () => {
    return (
        <ConnectedCarComponent />
    );
};


export default ConnectedCar;
