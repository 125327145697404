import React from 'react';

import './divider.css';

import { useClassnames } from '@/hooks/use-classnames';

const Divider = ({ className }: { className?: string }) => {
    const cn = useClassnames();

    return (
        <div className={cn('divider', className)} />
    );
};

export default Divider;
