import React from 'react';

import './heading.css';

import { useClassnames } from '@/hooks/use-classnames';

interface IHeadingProps {
    className?: string,
    text: string,
    icon?: string
}

const Heading = ({ className, icon, text }: IHeadingProps) => {
    const cn = useClassnames();
    return (
        <h1 className={cn('heading', className)}>
            <img className="heading__icon" src={icon} alt="icon" />
            <span className="heading__text" dangerouslySetInnerHTML={{ __html: text }} />
        </h1>
    );
};

export default Heading;
